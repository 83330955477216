import React, { useEffect, useState, useContext } from "react";
import {jsonList } from '../components/Data';
import date from 'date-and-time';

const useData = (json) => {
    const [data, setData] = useState(json);

    const handleNewData = ({change, code}) => {
        let copy = {...change};

        const matchedTimeTypeCategories = Object.values(change.menu.listOfCategories).filter(cat =>isCategoryMatchTimeType(change.timeTypeSettings, cat)).reduce((a,v)=>({...a, [v.id]: v}),{});
        const availableCategories = Object.values(matchedTimeTypeCategories).filter(cat =>isCategoryAvailable(cat, Object.values(copy.menu.listOfMeal).filter(meal=>meal['categoryId']===cat.id))).reduce((a,v)=>({...a, [v.id]: v}),{});
        
        const unmatchedTimeTypeCategories = Object.values(change.menu.listOfCategories).filter(cat =>!isCategoryMatchTimeType(change.timeTypeSettings, cat)).map(cat=>cat.id);
        const unavailableCategories = Object.values(unmatchedTimeTypeCategories).filter(cat =>!isCategoryAvailable(cat, Object.values(copy.menu.listOfMeal).filter(meal=>meal['categoryId']===cat.id),cat));
        const unavailableMealIds = Object.values(copy.menu.listOfMeal).filter(meal=>unavailableCategories.includes(meal.categoryId)).map(meal=>meal.id);

        copy.menu.listOfCategories = availableCategories;
        // copy.menu.listOfCategories = change.menu.listOfCategories;
        copy.unavailableCategories = unavailableCategories;
        copy.unavailableMealIds = unavailableMealIds;
        copy.serviceCharge = change.serviceCharge?? 0;
        copy.webDiscount = change.webDiscount?? 0;
        copy.defaultCharge = change.defaultCharge;
        copy.defaultWeb = change.defaultWeb;
        copy.restaurantCode = code;
        
        // console.log(copy);
        setData(copy);
    }

    return [data, handleNewData];
};

const isCategoryMatchTimeType = (timeTypeSettings, category) => {
  const tempWeekDay = new Date().getDay(); //0-6, Sun to Sat
  let weekDay;
  if (tempWeekDay === 0) {
    weekDay = 7;
  }else {
    weekDay = tempWeekDay -1;
  }
  
  if (category.dayAvailable[weekDay] === false) {
    
    return false;
  }

  // Get all time type that according to the time type id in category
  const timeTypeList = timeTypeSettings.filter((element) => category.timeTypeIdList.includes(element.id));
  
  for (let i = 0; i < timeTypeList.length; i++) {
    const timeType = timeTypeList[i];
    if (timeType.startTime == '-') {
      return true;
    }
    let tempList = timeType.startTime.split(":");
    let startTime = parseFloat(tempList[0]) + parseFloat(tempList[1]) / 60;
    tempList = timeType.endTime.split(":");
    let endTime = parseFloat(tempList[0]) + parseFloat(tempList[1]) / 60;

    const now = new Date();
    const formattedDate = date.format(now, 'HH:mm');
    tempList = formattedDate.split(":");
    const nowTime =
        parseFloat(tempList[0]) + parseFloat(tempList[1]) / 60;
    if (startTime > endTime) {
      const tempDouble = startTime;
      startTime = endTime;
      endTime = tempDouble;
    }
    if (nowTime >= startTime && nowTime <= endTime) {
      return true;
    }
  }

  return false;
}
const isCategoryAvailable = (category, meals) => {
  if(category.onlyTakeaway) return false;
  let availableMeals = [];
  availableMeals = meals.filter(meal => !meal['isDisable']);
  availableMeals = availableMeals.filter(meal => meal['isTakeawayValid']);
  if (availableMeals.length === 0) {
    return false;
  }
  return true;
}
export const dataContext = React.createContext();

export default useData;