import React, {useState, useRef, useContext, useEffect, useCallback} from 'react';
import '../style/Root.css';
import './Menu.css';
import Category from './Category';
import NoImgCategory from './NoImgCategory';
import { MenuData as data } from '../Data';
import {dummy} from '../newData';
import { widthContext } from '../../hooks/UseWindowWidth';
import { dataContext } from '../../hooks/UseData';
import { priceBaseContext } from '../../hooks/UsePriceBase';
import Loading from '../loading/Loading';

export default function Menu(props){
    const dineInDetail = useContext(dataContext);
    const isWebView = useContext(widthContext);
    const {handleClickedItem, clickedCategory, openOrderReview, openCart, setClickedCategory, openAddons, numberOfOrderDetails, numberOfOrdered} = props;
    //for catergory dropdown
    const [catergoryStyle, setCatergory] = useState({display: 'none'});
    const [menu, setMenu] = useState();
    const [menuSettings, setMenuSettings] = useState();
    const [mealList, setMealList] = useState();
    const [restaurant, setRestaurant] = useState();
    const priceBase = useContext(priceBaseContext);
    const [counter, setCounter] = useState(1);

    const increment = () => setCounter(origin=> origin+1);
    const toggleCategory =() => {
        setCatergory(catergoryStyle.display === 'none' ? {display: 'block'}: {display: 'none'}); 

    }
    
    useEffect(() => {
        if(dineInDetail) {
            const newMenu = [...Object.entries(dineInDetail.menu.listOfCategories).map(arr=>arr[1])].sort((a, b)=> a.omniIndex - b.omniIndex);
            const newMealList = [...Object.entries(dineInDetail.menu.listOfMeal).map(arr=>arr[1])].sort((a, b)=> a.omniIndex - b.omniIndex);
            setMenu(newMenu);
            setMealList(newMealList);
            setMenuSettings(dineInDetail.menu);
            setRestaurant(dineInDetail.restaurant);
        }
    }, [dineInDetail])
    

    if (!menu&&!mealList) {
        return(
            <Loading style={{position: "block"}}/>
        );
    }
    const cartQuantity = numberOfOrderDetails();

    return(
    <div id="app-menu" className="app-menu w:max w:full c:row bg:white">
        <div class="menu-head w:max w:full bg:head-bar shadow c:col">
            <h4 class="menu-head-title f:700 flex:1 p:sm">{restaurant.restaurantName}</h4>
            {/* <div onClick={openOrderReview} className="app-bar-btn">
                {numberOfOrdered>0&&
                <div className="cart-quantity">
                    <p>{numberOfOrdered}</p>
                </div>}
                <i class="f:black material-icons-outlined">
                receipt_long
                </i>
                <span>
                已下單餐點
                </span>
            </div> */}
            <div 
            onClick={()=>{
                openCart();
                // if(cartItemQty){
                //     openCart();
                // }else{
                //     alert("Shopping Cart is empty");
                // }
            }} 
            className="app-bar-btn mr:sm">
                {cartQuantity>0&&
                <div className="cart-quantity">
                    <p>{cartQuantity}</p>
                </div>}
                <i class="f:black material-icons-outlined">
                shopping_cart
                </i>
                <span>
                未下單
                </span>
            </div>
        </div>
        <div class="menu-body w:max w:full">
            {menuSettings.posImage?.remotePath&&
                <div 
                    class="menu-img bg"
                    style={{"backgroundImage": `url('${menuSettings.posImage.remotePath}')`}}
                    >
                </div>
            }
            <div className="menu-category c:row gap:sm p:sm" style={menuSettings? {}:{}}>
                {menu.map((category, index)=>{
                    return (
                    category.posImage?.remotePath?
                    <Category 
                    hasImg={!!menuSettings.posImage}
                    clickedCategory={clickedCategory}
                    setClickedCategory={setClickedCategory}
                    priceBase={priceBase}
                    handleClickedItem={handleClickedItem} 
                    toggleCategory={toggleCategory} 
                    openAddons={openAddons} 
                    mealList={mealList} 
                    category={category} 
                    style={catergoryStyle}/>
                    :<NoImgCategory
                    hasImg={!!menuSettings.posImage}
                    clickedCategory={clickedCategory}
                    setClickedCategory={setClickedCategory}
                    priceBase={priceBase}
                    handleClickedItem={handleClickedItem} 
                    toggleCategory={toggleCategory} 
                    openAddons={openAddons} 
                    mealList={mealList} 
                    category={category} 
                    style={catergoryStyle}/>
                    )
                })}
            </div>
            {/* <div style={{height: "1000px"}}></div> */}
            {/* <button className="w:full" onClick={increment}>{`counter: ${counter}`}</button> */}
        </div>
    </div>
    );
}