import React, {useState, useEffect, useContext} from 'react';
import '../style/Root.css';
import './Cart.css';
import { widthContext } from '../../hooks/UseWindowWidth';
import { HeadBar } from '../Elements';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { languageContext } from '../../hooks/UseLanguage';
import { priceBaseContext } from '../../hooks/UsePriceBase';
import { dataContext } from '../../hooks/UseData';
import { postOrder } from '../../api/checkoutApi';
import {dummy} from '../newData';
import Loading from '../loading/Loading';
import mealFactory from '../../factory/mealFactory';
import orderDetailFactory from '../../factory/orderDetailFactory';
import emptyCart from '../../assets/emptyCart.png';


export default function NewCart(props){
    const [isLoading, setIsLoading] = useState(false);
    const webOrderDetail = useContext(dataContext)||undefined;
    const isWebView = useContext(widthContext);
    const lang = useContext(languageContext);
    const priceBase = useContext(priceBaseContext);
    const {
        closeCart, 
        style,
        handleCart2Addons
    } = props;
    // if(true){
    //     return(
    //         <div className="cart-overlay">
    //             <Loading style={{position: "relative"}}/>
    //         </div>
    //     );
    // }
    return (
    (isWebView)?
    <div class="modal " style={style.web}>
        
        {isLoading&&<div className="cart-overlay">
            <Loading style={{position: "relative"}}/>
        </div>}
        <div class="modal-content c:row">
            <div class="modal-head py:sm">
                <div onClick={()=>{
                    closeCart();
                    // handleCart2Addons(false);
                    }} class="head-btn close-btn bg:grey">
                        <i class="f:icon f:grey material-icons-outlined">
                            close
                        </i>
                </div>
            </div>
            {/* <div id="app-addons" class="addons-body h:full w:full c:row p:sm gap:xs bg:grey r:sm">
                    <div class="w:full center border:bottom pb:xs">
    
                    </div>
                </div> */}
            <CartBody style={style.web} props={props} isWebView={isWebView} webOrderDetail={webOrderDetail} setIsLoading={setIsLoading} closeCart={closeCart} lang={lang} priceBase={priceBase}/>
            
        </div>
    </div>:
    <div class="app-cart" style={style.mobile}>
        {isLoading&&<div className="cart-overlay">
            <Loading style={{position: "relative"}}/>
        </div>}
        <HeadBar className="cart-head" operation={closeCart} title="未下單餐點"/>
        <CartBody style={style.mobile} props={props} isWebView={isWebView}  webOrderDetail={webOrderDetail} setIsLoading={setIsLoading} closeCart={closeCart} lang={lang} priceBase={priceBase}/>
    </div>
    );
}

const checkDineInTime = (expiredTime) => {
    const now = new Date().valueOf();
    if(now>expiredTime){
        return false;
    }
    return true;
}

function roundDown(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.floor(value * multiplier) / multiplier;
}
function roundUp(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.ceil(value * multiplier) / multiplier;
}

function fixed(value, precision) {
    return parseFloat(Number.parseFloat(value).toFixed(precision));
}

export const CartBody = React.memo(({style, isWebView, props, webOrderDetail, closeCart, lang, priceBase, setIsLoading})=>{
    const [total, setTotal] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const { token } = useParams();
    const {
        orderDetails,
        updateOrderDetail,
        postOrder2db,
        openCheckout,
        handleSetOrdered
    } = props;
    const detailFC = orderDetailFactory(priceBase);
    const subTotal = orderDetails.reduce((total, orderDetail)=>total + detailFC.getOrderDetailTotalPrice(orderDetail)*orderDetail.quantity, 0);
    const totalQuantity = orderDetails.reduce((quantity, orderDetail)=>quantity + orderDetail.quantity, 0);



    const isDiscountOn = webOrderDetail.defaultWeb;
    const discountPercentage = webOrderDetail.webDiscount;
    const subTotalFixed = fixed(subTotal, 1);
    const discountAmount = fixed(subTotal*discountPercentage/100, 1);
    const totalPrice = isDiscountOn? fixed(subTotal-discountAmount,1): fixed(subTotal,1);
    // console.log(discountPercentage);
    return(
    <div id="cart-body" class={`cart-body ${isWebView&&"web-cart-body"} h:full c:row px:sm gap:xs bg:grey r:sm`}>
        <div className="c:row flex:1 gap:xs" >
            <div className="head-bar"/>
            {orderDetails.length===0&&
            <div className="h:full w:full c:row c:center c:middle flex:1">
                <div className="empty-cart-img">
                    <img src={emptyCart} alt="Logo" />
                </div>
                <h6>還沒有選取餐點</h6>
            </div>
            }
            {orderDetails.map((orderDetail, index)=>{
                return(
                    <CartItem index={index} props={props} updateOrderDetail={updateOrderDetail} orderDetail={orderDetail} lang={lang} priceBase={priceBase}/>
                );
            })}
            <div className="sized-box-checkout"/>
        </div>

        <div className="cart-checkout c:row">
            <div className="flex:1">
                <div className="price-overview">
                    <h5>{totalQuantity} 項食物</h5>
                    <h5 className="title">小計</h5>
                    <h5 className="price">${subTotalFixed}</h5>
                    {isDiscountOn&&discountPercentage>0&&<div/>}
                    {isDiscountOn&&discountPercentage>0&&<h5 className="title">外賣折扣 ({discountPercentage}% off)</h5>}
                    {isDiscountOn&&discountPercentage>0&&<h5 className="price">${discountAmount}</h5>}
                    <div/>
                    <h5 className="title">總共</h5>
                    <h5 className="price">${totalPrice}</h5>
                </div>
            </div>
            <button
                className="cart-checkout-btn"
                onClick={()=>{
                    if(orderDetails.length===0){
                        alert("請先點餐");
                        closeCart();
                    }else if(!webOrderDetail.menu.isAllowWebOrder){
                        alert("對不起，餐廳外賣自取平台暫時關閉，請稍後再點餐");
                    }else{
                        openCheckout();
                    }
                }}
                >
                <h4>確認下單</h4>
            </button>
        </div>
    </div>

    );
});

const CartItem = ({index, orderDetail, props, lang, priceBase, updateOrderDetail}) => {
    const {handleClickedItem, openAddons, handleCart2Addons} = props;
    const {refId} = useParams();
    const history = useHistory();
    const mealFC = mealFactory();
    const detailFC = orderDetailFactory(priceBase);
    const orderPrice = fixed(detailFC.getOrderDetailTotalPrice(orderDetail)*orderDetail.quantity,1);
    const id = orderDetail.id;
    const quantity = orderDetail.quantity;

    const increment = () => {
        const newQuantity = quantity + 1;
        updateOrderDetail({id, newOrderDetail: {...orderDetail, quantity: newQuantity}});
    }
    const decrement = () => {
        const newQuantity = quantity - 1;
        updateOrderDetail({id, newOrderDetail: {...orderDetail, quantity: newQuantity}});
    }

    const directToAddons = () => history.push({
        pathName: `/restaurant/dinein/${refId}/editItem`,
        state: {}
    });
    const openCartAddons = () => {
        // console.log(orderDetail);
        handleCart2Addons(orderDetail);
        handleClickedItem(orderDetail.meal.id);
        openAddons();
    }

    return (
        <div key={`cart-item-card-${index}`} className="cart-item-card c:row">
            <div className="flex:1">
                <div className="c:col">
                    <div onClick={openCartAddons} className="cart-item-detail flex:1 p:xs">
                        <div className="c:col c:between">
                            <h6>{orderDetail.meal[lang]}</h6>
                            <h6>${orderDetail.meal[priceBase]}</h6>
                        </div>
                        {orderDetail&&orderDetail.itemDetails.map(itemDetail=>{
                            {/* console.log(itemDetail); */}
                            if(!itemDetail) return null;
                            const itemStack = itemDetail.itemStack;
                            const optionStacks = itemDetail.optionStacks;
                            const isStandardItem = mealFC.isStandardItem(itemStack);
                            const item = isStandardItem? mealFC.getItemParent(itemStack):mealFC.getItemChildren(itemStack)[0];
                            const itemPrice = (item[priceBase])? parseFloat(item[priceBase]):0;
                            return(
                                <div className="c:row">
                                    <div className="c:col c:between">
                                        <p>{item[lang]}</p>
                                        <p>{itemPrice>0&&`+$${itemPrice}`}</p>
                                    </div>
                                    {optionStacks.map(optionStack=>{
                                        const optionParent = mealFC.getOptionParent(optionStack);
                                        const optionChild = mealFC.getItemChildren(optionStack)[0];
                                        const optionIsExist = optionParent&&optionChild;
                                        const optionChildPrice = (optionIsExist&&optionChild[priceBase])? parseFloat(optionChild[priceBase]):0;
                                        return (
                                            <div className="c:col c:between">
                                                <p>{optionIsExist&&`(${optionParent[lang]}: ${optionChild[lang]})`}</p>
                                                <p>{optionChildPrice>0&&`+$${optionChildPrice}`}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                        );
                        })}
                    </div>
                    <div className="cart-item-btn-gp c:col c:middle c:between">
                        <button type="button" onClick={decrement} class="cart-item-btn">
                            <i class="f:icon f:grey material-icons-outlined">
                                remove
                            </i>
                        </button>
                        <h6 class="cart-item-quantity f:orange">{quantity}</h6>
                        <button type="button" onClick={increment} class="cart-item-btn">
                            <i class="f:icon f:grey material-icons-outlined">
                                add
                            </i>
                        </button>
                    </div>
                </div>
            </div>
            <div onClick={openCartAddons} className="c:col c:between p:xs">
                <h6> {orderDetail.specialInstruction&& `《 特別要求： ${orderDetail.specialInstruction}》`}</h6>
                <h6>${orderPrice}</h6>
            </div>
        </div>
    );
}





