import React, {useState, useRef, useContext, useEffect} from 'react';
import '../style/Root.css';
import './Menu.css';
import {languageContext} from '../../hooks/UseLanguage';

export default function NoImgCategory(props){
    const lang = useContext(languageContext);
    const {
        setClickedCategory,
        handleClickedItem, 
        clickedCategory,
        category, 
        priceBase,
        mealList, 
        openAddons, 
        toggleCategory, 
        style} = props;
    const {display} = style;
    const categoryId = category.id;
    const categoryMeal = mealList.filter(obj=>obj.categoryId === categoryId);
    const styleOption = {
        categoryImg: {
            "none": "category-img h:full bg r:xs c:row c:between",
            "block": "category-img h:full bg rt:xs c:row c:between",
        },
        categoryBar: {
            none: "category-bar-img bg:overlay c:col c:between c:middle rb:xs p:sm",
            block: "category-bar-img bg:overlay c:col c:between c:middle p:sm",
        },
        dropdownBtn: {
            none: "arrow_drop_down",
            block: "arrow_drop_up",
        }
    }    
    const myRef = useRef(0);
    useEffect(() => {
        if(clickedCategory===categoryId){
            const menu = document.getElementById("app-menu");
            const y = myRef.current.offsetTop  - 70;
            menu.scrollTo({top: y, behavior: 'smooth'});
        }
    }, [style]);
    return(
    <div key={`category-${categoryId}`} id={`category-${categoryId}`}  className="category w:full c:row r:sm" ref={myRef}>
        <div 
            onClick={()=>{
                toggleCategory();
                setClickedCategory(categoryId);
            }} 
            className="category-bar bg:overlay c:col c:between c:middle r:xs py:sm pl:sm pr:xs" >
            <div className="c:row">
                <h5 className="">{category[lang]}</h5>
                {category.secName&&<h5 className="secondary-name">{category.secName}</h5>}
            </div>
            <div className="category-head-btn btn:circle center">
                <i className="f:white f:3xl material-icons-outlined">
                {styleOption.dropdownBtn[display]}
                </i>
            </div>
        </div>
        <div className="category-body bg:white" style={style}>
            {categoryMeal.filter(meal=>!meal.isDisable&&meal.isTakeawayValid).map((meal, index)=>{
                const hasImg = !!meal.img;
                let itemImg = {"backgroundImage": `url(${meal?.img})`};
                let itemNoImg = {"backgroundColor": "#ff9782"};
                
                return <ClickableDiv 
                        myRef={myRef}
                        categoryId={categoryId}
                        priceBase={priceBase}
                        lang={lang}
                        hasImg={hasImg}
                        handleClickedItem={handleClickedItem}
                        meal={{...meal}} 
                        itemImg={itemNoImg} 
                        openAddons={openAddons}
                        setClickedCategory={setClickedCategory}
                        />;
            })}
        </div>
        
    </div>
    );
}

export const ClickableDiv = (props) => {
    const {lang, myRef, priceBase, hasImg, meal, itemImg, openAddons, categoryId, setClickedCategory, handleClickedItem} = props;
    
    const executeScroll = () => {
        const menu = document.getElementById("app-menu");
        const y = myRef.current.offsetTop  - 70;
        menu.scrollTo({top: y, behavior: 'smooth'});
    };
    return (
    <div 
        id={`meal-${meal.id}`}
        onClick={() => {
            handleClickedItem(meal.id);
            // setClickedCategory(myRef);
            openAddons();
            executeScroll();
        }} 
        key={`meal-${meal.id}`}
        className="category-item c:col c:between p:sm" >
        <div className={`category-item-info c:row gap:xs ${!hasImg&&"c:center"}`}>
            <h5 className="f:500">{meal[lang]}</h5>
            {meal.secName&&<h5 className="secondary-name">{meal.secName}</h5>}
            {meal.description&&<p className="f:500 f:light-grey">{meal.description}</p>}
        </div>
        <h5 className={`category-item-price c:row f:500 ${!hasImg&&"c:center"}`} style={{textAlign: "right"}}>${meal[priceBase]}</h5>
        {hasImg&&<div className="category-item-img bg r:sm shadow:light" style={itemImg}/>}
    </div>
    );
  }
