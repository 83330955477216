import './Privacy.css';

function Terms(){
    return (
        <div className="privacy">
            <h1>服務條款</h1>
            <p>
            感謝你使用R Cube App Studio Limited (以下簡稱 “本公司” 、“我們”) 其下的網上外賣自取平台，本服務條款 (以下簡稱「條款」) 範圍涵蓋我們所提供的服務、用戶端軟體及網站 (以下簡稱「服務」) 之存取及使用。我們的隱私權政策說明我們如何蒐集及使用您的資訊。使用我們的服務，即代表您同意遵循本條款、我們的隱私權政策。
            <br/><br/>另外，您必須年滿18歲才能使用本公司的網上外賣自取平台。
            <br/><br/>服務條款如下：

            </p>

            <h2>1. 網上外賣自取平台</h2>
            <p>
            用戶可透過網上外賣自取平台進行網上下單，並通過信用卡向相關商家付款。提交外賣訂單後，用戶需前往訂單上顯示的餐廳地址領取食品。
            <br/><br/>每項食品的價格會顯示在渠道上。 所有價格可能會有所變動，但這些變更是不會影響您通過渠道成功付款的任何外賣訂單。
            <br/><br/>一旦您的外賣訂單成功付款，即代表物品銷售已完成。此時，就您於渠道上訂購的任何食品，您和此相關的商家的買賣合同已經形成。本公司將根據其系統上的記錄以確定您的付款時間。
            <br/><br/>您有義務確保您於渠道上註冊的電郵地址和電話號碼是正確，以供商戶就有關外賣訂單的任何問題與您進行溝通。
            <br/><br/>如您未能在成功付款後的120分鐘內從取餐處領取已訂購的食品，您同意您放棄索取該已訂購食品的權利，並無權就該已訂購的食品要求作出任何退款。另外，您亦同意及接受商家有權不向您提供已訂購的食品。
            <br/><br/>酒精類飲料只能出售給18歲或以上的用戶。 通過訂購包含酒精的外賣訂單，您明確聲明並保證您已年滿18歲，而在取餐處的工作人員的合理要求下，您將需要向其出示您的香港身份證，以核實您的年齡。

            </p>

            <h2>2. 取消和退貨</h2>
            <p>對於任何外賣訂單，您有權在付款前通過渠道取消外賣訂單。任何外賣訂單在成功付款後都不能被您取消及您將無法獲得任何退款。
            <br/><br/>所有通過渠道成功付款的食品都不能由您作出變更或退回以獲得退款。</p>

            <h2>3. 責任</h2>
            <p>
            所有食品供應均視乎情況而定，商家會在確認外賣訂單後就無法提供的任何食品與您聯絡，本公司將不會對此承擔任何責任。
            <br/><br/>本公司僅為您和商家提供渠道作為媒體以完成任何外賣訂單的網上訂購和付款流程，並不就任何商家提供的任何食品做預備，保證或承擔責任。履行您的外賣訂單是商家的責任。
            <br/><br/>對於商家、參與餐廳/商店和/或取餐點的任何行為或疏忽 (包括服務不足、不正確的商品或價格、食品或飲料的質量、準備外賣訂單時所需要的時間等) ，本公司不會承擔任何責任。
            <br/><br/>如您對任何食物有過敏，您應在外賣訂單下單前聯絡相關的商家。 如您訂購的任何食品不符合您的飲食或任何其他要求和限制，本公司將不承擔任何責任。
            <br/><br/>本公司對任何食品的數量、質量、味道或新鮮度不會承擔任何責任。
            <br/><br/>在法律允許的最大範圍內，對於因合同、侵權（包括但不限於疏忽）或因您使用或無法使用點餐服務而產生的任何直接、間接、特殊或後果性損失或損害，本公司不承擔任何責任。</p>

            <h2>4. 服務條款修正</h2>
            <p>服務條款將因應需求隨時進行修正，修正後的條款將刊登於網站上。</p>

            <h2>聯繫方式</h2>
            <p>
                如果您對本隱私權政策有任何疑問，請聯繫我們：<br/>
                R Cube App Studio Limited<br/>
                電郵：support@rcubestudio.com.hk<br/>
                電話：+852 5662 9356<br/>

                版本日期：2021年10月25日<br/>
            </p>
        </div>
    );
}

export default Terms;
