import React, {useState, useRef, useEffect, useCallback, useContext, useMemo} from 'react';
import {useParams, Switch, Route, useRouteMatch} from "react-router-dom";
import '../style/Root.css';
import './Shop.css';
import Addons from '../addons/Addons';
import Menu from "../menu/Menu";
import Cart from "../cart/Cart";
import Checkout from '../checkout/Checkout';
import OrderReview from '../orderReview/OrderReview';
import InfoBar from '../infoBar/InfoBar';
import useWindowsWidth, {widthContext} from '../../hooks/UseWindowWidth';
import useLanguage, {languageContext} from '../../hooks/UseLanguage';
import usePriceBase, {priceBaseContext} from '../../hooks/UsePriceBase';
import useData, {dataContext} from '../../hooks/UseData';
import useOverlay from '../../hooks/UseOverlay';
import useCart from '../../hooks/UseCart';
import useAddons from '../../hooks/UseAddons';
import { getWebOrderDetail } from '../../api/menuApi';
import { jsonList } from '../Data';
import {dummy} from '../newData';
import Loading from '../loading/Loading';
import useOrderDetail from '../../hooks/UseOrderDetail';
import NewCart from '../cart/NewCart';
import closed from '../../assets/closed.png';
import error from '../../assets/error.png';

function Shop() {
    const [data, handleNewData] = useData('');
    const [language] = useLanguage("priName");
    const [priceBase] = usePriceBase("takeawayPrice");
    const isWebView = useWindowsWidth(768);
    const [restaurant, setRestaurant] = useState();
    const [pageError, setPageError] = useState(false);
    const [webOrderIsClosed, setWebOrderIsClosed] = useState(false);
    const {code} = useParams();

    useEffect(async ()=>{
        
        // const queryParams = new URLSearchParams(window.location.search);
        // const refId = queryParams.get('value');
        const [webOrderDetail, err] = await getWebOrderDetail(code);

        if(err) {
            setPageError(true);
        }else{
            if(webOrderDetail.menu.isAllowWebOrder&&webOrderDetail.isEnabledWebOrder){
                handleNewData({change: webOrderDetail, code: code});
                document.title = webOrderDetail.restaurant.restaurantName;
                setWebOrderIsClosed(false);
            }else{
                setWebOrderIsClosed(true);
            }
        }
        // console.log(webOrderDetail);
        // setRestaurant(id);
    }, [code]);
    // console.log(webOrderIsClosed);
    if(webOrderIsClosed){
        return <RestaurantClosed/>;
    }
    if(pageError){
        return <ErrorPage/>;
    }
    if (!data) {
        return(
            <Loading/>
        );
    }

    return (
        <dataContext.Provider value={data}>
        <priceBaseContext.Provider value={priceBase}>
        <languageContext.Provider value={language}>
        <widthContext.Provider value={isWebView}>
            <ShopView isWebView={isWebView}/>
        </widthContext.Provider>
        </languageContext.Provider>
        </priceBaseContext.Provider>
        </dataContext.Provider>
    );
}

export const ShopView = React.memo(({isWebView, refId})=>{
    const webOrderDetail = useContext(dataContext);
    // console.log("re-rendered!");
    const [page, setPage] = useOverlay();
    // const [pendingOrder, cartItems, cartTotal, cartItemQty, {checkoutCart, addCartItem, editCartItem, removeCartItem, setCartTotal}] = useCart();
    const {orderDetails, ordered, addOrderDetail, updateOrderDetail, updateCheckoutOrder, numberOfOrderDetails, getCheckoutOrder, postOrder2db} = useOrderDetail();
    const [clickedItem, setClickedItem] = useState();
    const [clickedCategory, setClickedCategory] = useState();
    // const [ordered, setOrdered] = useState([]);
    // const handleSetOrdered = useCallback((orderedDetails)=>setOrdered(origin=>[...origin, ...orderedDetails]), []);
    const [selectedOrderDetail, setSelectedOrderDetail] = useState(false);
    const {meal, itemStacks} = useAddons(clickedItem);

    const handleCart2Addons = useCallback((orderDetail) => {
        setSelectedOrderDetail(orderDetail);
    }, []);
    const resetSelectedOrderDetail = useCallback(() => {
        setSelectedOrderDetail(false);
    }, []);
    const handleClickedItem = useCallback((mealId) => {
        setClickedItem(mealId);
    }, []);

    //for Addons overlay
    const openAddons = () => {
        setPage({type: 'addons', payload: 'open'});
    }
    const closeAddons = () => setPage({type: 'addons', payload: 'close'});
    //for Cart overlay
    const openCart = () => setPage({type: 'cart', payload: 'open'});
    const closeCart = () => setPage({type: 'cart', payload: 'close'});
    //for Checkout overlay
    const openCheckout = () => setPage({type: 'checkout', payload: 'open'});
    const closeCheckout = () => setPage({type: 'checkout', payload: 'close'});
    //for Order Review overlay
    const openOrderReview = () => {
        closeCart();
        closeCheckout();
        setPage({type: 'orderReview', payload: 'open'});
    };
    const closeOrderReview = () => {
        setPage({type: 'orderReview', payload: 'close'});
    }
    
    return (

        <div class="app-root">
            
            <div class="app-body" style={{height: "100%"}}>
                <InfoBar/>
                <OrderReview ordered={ordered} closeOrderReview={closeOrderReview} style={page.orderReview.style}/>
                <Checkout openOrderReview={openOrderReview} getCheckoutOrder={getCheckoutOrder} updateCheckoutOrder={updateCheckoutOrder} postOrder2db={postOrder2db} orderDetails={orderDetails} closeCheckout={closeCheckout} style={page.checkout.style}/>
                <NewCart openCheckout={openCheckout} postOrder2db={postOrder2db} handleCart2Addons={handleCart2Addons} handleClickedItem={handleClickedItem} openAddons={openAddons} updateOrderDetail={updateOrderDetail} orderDetails={orderDetails} closeCart={closeCart} style={page.cart.style}/>
                <Addons clickedCategory={clickedCategory} resetSelectedOrderDetail={resetSelectedOrderDetail} selectedOrderDetail={selectedOrderDetail} addOrderDetail={addOrderDetail} page={page} itemStacks={itemStacks} meal={meal} closeAddons={closeAddons} style={page.addons.style} />
                <Menu setClickedCategory={setClickedCategory} numberOfOrdered={ordered.length} numberOfOrderDetails={numberOfOrderDetails} clickedCategory={clickedCategory} handleClickedItem={handleClickedItem} openOrderReview={openOrderReview} openAddons={openAddons} openCart={openCart}/>
            </div>
            
        </div>
        );
});

const RestaurantClosed = () => {
    return (
        <div className="restaurant-closed">
            <div>
                <div className="restaurant-closed-img">
                    <img src={closed} alt="Logo" />
                </div>
                <h6>餐廳外賣自取平台暫時關閉</h6>
            </div>
        </div>
    );
}
export const ErrorPage = () => {
    return (
        <div className="restaurant-closed">
            <div>
                <div className="restaurant-closed-img">
                    <img src={error} alt="Logo" />
                </div>
                <h6>餐廳不存在或出現未知錯誤</h6>
                <h6>請重新掃描二維碼 (QR Code)</h6>
            </div>
        </div>
    );
}

export default Shop;