import React, {useState, useRef, useEffect} from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import Shop from './components/shop/Shop';
import Addons from './components/addons/Addons';
import NewCart from './components/cart/NewCart';
import Loading from './components/loading/Loading';
import error from './assets/error.png';
import { ErrorPage } from './components/shop/Shop';
import Terms from './components/terms/Terms';
import Privacy from './components/terms/Privacy';

function App() {
  return (
    <Switch>
      <Route exact path="/privacy">
        <Privacy/>
      </Route>
      <Route exact path="/terms">
        <Terms/>
      </Route>
      <Route exact path="/restaurant/webOrder/:code">
        <Shop/>
      </Route>
      <Route path="/" render={()=><ErrorPage/>} />
      {/* <Route exact path="/restaurant/dinein/:refId/editItem">
        <Addons/>
      </Route> */}
      {/* <Route exact path="/">
        <Loading/>
      </Route> */}
    </Switch>
  );
}


export default App;
