import axios from "axios";
import Routes from "../routes/Routes";



export async function stripeCheckout({code, totalPrice}) {
    try{
        const intent_secret = await axios.post(Routes.url + `/webOrderCheckout?code=${code}`, {cartTotal: totalPrice});
        return intent_secret;
    }catch(error){
        console.log(error);
        return [null, JSON.stringify(error)];
    }
}

export async function postOrder({order, code}) {
    try{
        // console.log(order);
        const res = await axios.post(Routes.url + `/sendReceiptEmail?code=${code}`, {order: order});
        const orderData = JSON.parse(JSON.stringify(res));
        return [res, null];

    }catch(error){
        console.log(error);
        return [null, JSON.stringify(error.response?.data)];
    }
}