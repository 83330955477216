import React, { useContext, useMemo, useState, useEffect } from 'react';
import {
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement,
    CardElement,
    useStripe, 
    useElements,
    Elements
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import {useParams} from "react-router-dom";
import '../style/Root.css';
import './Checkout.css';
import {HeadBar} from '../Elements';
import { Formik } from 'formik';
import { widthContext } from '../../hooks/UseWindowWidth';
import { dataContext } from '../../hooks/UseData';
import { priceBaseContext } from '../../hooks/UsePriceBase';
import mealFactory from '../../factory/mealFactory';
import orderDetailFactory from '../../factory/orderDetailFactory';
import {stripeCheckout} from '../../api/checkoutApi';
import Loading from '../loading/Loading';
import { getWebOrderDetail } from '../../api/menuApi';



function Checkout(props){
    const isWebView = useContext(widthContext);
    const webOrderDetail = useContext(dataContext);
    const {openOrderReview, closeCheckout, updateCheckoutOrder, orderDetails, getCheckoutOrder, postOrder2db, style} = props;
    const publicKey = webOrderDetail.publicKey;
    // const pk = 'pk_test_51JKvs82SFCuJVeGbLMuwVQkGOVqKxx2216PY6h00KgftjjwbEVZY5xxyeIecj8LdXmUW1OfbPid4vp3GLAzgDdm900V4CEilTl';

    // if (!publicKey) {
    //     alert("Web Ordering is currently not available");
    //     // return(
    //     //     <Loading/>
    //     // );
    // }
    const stripePromise = loadStripe(publicKey, {locale: 'zh-HK'});
    return(
        <Elements stripe={stripePromise}>
            {(isWebView)?
            <div class="modal" style={{...style.web, "z-index": "100"}}>
                <div class="modal-content c:row">
                    <div class="modal-head py:sm">
                        <div onClick={closeCheckout} class="head-btn close-btn bg:grey">
                                <i class="f:icon f:grey material-icons-outlined">
                                    close
                                </i>
                        </div>
                    </div>
                    <CheckoutBody isWebView={isWebView} webOrderDetail={webOrderDetail} updateCheckoutOrder={updateCheckoutOrder} openOrderReview={openOrderReview} getCheckoutOrder={getCheckoutOrder} postOrder2db={postOrder2db} openOrderReview={openOrderReview} orderDetails={orderDetails} style={style.mobile}/>
                </div>
            </div>:
            <div class="app-checkout" style={style.mobile}>
                <HeadBar className="checkout-head" operation={closeCheckout} title="付款"/>


                <CheckoutBody isWebView={isWebView} webOrderDetail={webOrderDetail} updateCheckoutOrder={updateCheckoutOrder} openOrderReview={openOrderReview} getCheckoutOrder={getCheckoutOrder} postOrder2db={postOrder2db} openOrderReview={openOrderReview} orderDetails={orderDetails} style={style.mobile}/>
            </div>}
        </Elements>
    );
}

const options = {
    hidePostalCode: true,
    style: {
    base: {
        lineHeight: "40px",
        fontSize: "15px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
        color: "#aab7c4"
        }
    },
    invalid: {
        color: "#fa755a"
    }
    }
};
const isEmpty = (string) => {
    if (/\A\s*\z/.test(string)) return true;
	if (string&&`${string}`.trim() === '') return true;
	else return false;
};
const isEmail = (email) => {
	const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (email.match(emailRegEx)) return true;
	else return false;
};
const validateForm = (values) => {
    let errors = {};

    if(values.name === "") errors.name = "需填";
    if(values.email === "") errors.email = "需填";
    if(!isEmail(values.email)) errors.email = "請填寫有效電郵地址";
    if(values.tel === "") errors.tel = "需填";
    if(values.tel.replace(/\s+/g, '').length < 8) errors.tel = "請填寫有效電話";

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
}

function roundDown(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.floor(value * multiplier) / multiplier;
}
function roundUp(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.ceil(value * multiplier) / multiplier;
}
function fixed(value, precision) {
    return parseFloat(Number.parseFloat(value).toFixed(precision));
  }

const CheckoutBody = ({isWebView, openOrderReview, cartTotal, style, webOrderDetail, updateCheckoutOrder, getCheckoutOrder, orderDetails, postOrder2db}) => {
    const [loading, setLoading] = useState(false);
    const { code } = useParams();
    const stripe = useStripe();
    const elements = useElements();
    const [values, setValues] = useState({name: "", email: "", tel: ""});
    const [errors, setErrors] = useState({});
    const [cardComplete, setCardComplete] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [cardInputError, setCardInputError] = useState({});
    const priceBase = useContext(priceBaseContext);
    const detailFC = orderDetailFactory(priceBase);
    const subTotal = orderDetails.reduce((total, orderDetail)=>total + detailFC.getOrderDetailTotalPrice(orderDetail)*orderDetail.quantity, 0);
    const totalQuantity = orderDetails.reduce((quantity, orderDetail)=>quantity + orderDetail.quantity, 0);
    const nameChangeHandler = (event) => setValues(obj=>({...obj, name: event.target.value}));
    const emailChangeHandler = (event) => setValues(obj=>({...obj, email: event.target.value}));
    const telChangeHandler = (event) =>  setValues(obj=>({...obj, tel: event.target.value}));
    const toggleAgreeTerms = () => setAgreeTerms(origin=>!origin);
    
    const isDiscountOn = webOrderDetail.defaultWeb;
    const discountPercentage = webOrderDetail.webDiscount;
    const discountAmount = isDiscountOn? fixed(subTotal*discountPercentage/100, 1): 0;
    const totalPrice = isDiscountOn? fixed(subTotal-discountAmount,1): fixed(subTotal,1);
    // const [orderedDetails, setOrderDetails] = useState([]);
    // const [orderNumber, setOrderNumber] = useState(false);

    
    const handleSubmit = async (event) => {
        // Block native form submission.

        event.preventDefault();
        if(!agreeTerms){
            alert("請細閱及同意有關條款");
            return;
        }
        if (!orderDetails.length){
            alert("請先點餐");
            return;
        };
        if(totalPrice < 4){
            alert("交易金額不能少於$4");
            return;
        }
        try{
            // 20220414 Add loading
            setLoading(true);

            const [latestDetail, err] = await getWebOrderDetail(code);
            if(!latestDetail.menu.isAllowWebOrder||!latestDetail.isEnabledWebOrder){
                throw {message: "外賣自取平台暫時關閉，請稍後再作嘗試"};
            }
            // console.log(latestDetail);
            if(latestDetail.menu.defaultPrinterIp === ''){
                throw {message: "餐廳印刷機出錯，請稍後再作嘗試"};

            }
            const disabledMeals = Object.values(latestDetail.menu.listOfMeal).filter((obj)=>obj.isDisable||!obj.isTakeawayValid).map(obj=>obj.id);
            const disabledMealIds = disabledMeals.filter(id=>orderDetails.find((obj)=>id===obj.meal.id));
            const disabledOrders = disabledMealIds.map(id=>orderDetails.find((obj)=>id===obj.meal.id));
            if (disabledOrders.length > 0){
                const disabledOrdersInStr = disabledOrders.map(obj=>obj.meal.priName).join('、');
                alert(`對不起，${disabledOrdersInStr}暫時未能供應，請更改你的點餐後再下單，以及重新載入網頁以獲取最新菜單`);
                setLoading(false);
                return;
            };
            const [isValid, formErrors] = validateForm(values);
            if(!isValid){
                setErrors(formErrors);
                setLoading(false);
                return;
            }
            setErrors({});

            // console.log("requesting...");
            const checkoutOrder = getCheckoutOrder({totalPrice, discountPercentage, discountAmount, customerInfo: {...values}});
            // console.log(checkoutOrder);
            // const localURL = "http://localhost:8080/";
            const isRestaurantOnlineRes = await axios.post(`https://caterat-pos.df.r.appspot.com/listenIsRestaurantOnline?code=${webOrderDetail.restaurantCode}`, {order: checkoutOrder}, { timeout: 10000 })
                                                        .catch(isOnlineError=>{
                                                            throw {message: "線路繁忙，請重新載入並檢查餐點狀態"};
                                                        });
            if(isRestaurantOnlineRes.status===200){
                // console.log(isRestaurantOnlineRes.data); // This will sometime be empty
                const {listenerId, orderNumber, docId}= isRestaurantOnlineRes.data;

                if (!stripe || !elements) {
                    // Stripe.js has not loaded yet. Make sure to disable
                    // form submission until Stripe.js has loaded.
                    return;
                }
                const cardElement = elements.getElement(CardElement);
                cardElement.update({disabled: true});
                if (cardComplete) {
                    setLoading(true);
                }
                
                // Use your card Element with other Stripe.js APIs
                const payload = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });

                if (payload.error) {
                    // console.log(payload.error);
                    setCardInputError(payload.error);
                    setLoading(false);
                    cardElement.update({disabled: false});
                    return;
                } else {
                    setCardInputError({});
                }

                const intent_secret = await stripeCheckout({code, totalPrice});
                const paymentResult = await stripe.confirmCardPayment(intent_secret.data.client_secret,{
                        payment_method: {card: cardElement}
                    })
                if (paymentResult.error) {
                    setCardInputError(paymentResult.error);
                    setLoading(false);
                    cardElement.update({disabled: false});
                } else {
                    if (paymentResult.paymentIntent.status === 'succeeded') {
                        updateCheckoutOrder({order: checkoutOrder, orderNumber});
                        const addWebOrderResult = await postOrder2db({code, totalPrice, discountPercentage, discountAmount, orderNumber, customerInfo: {...values}});
                        if(addWebOrderResult.status === 500){
                            setLoading(false);
                            cardElement.update({disabled: false});
                            throw "發生未知錯誤，請稍後再作嘗試";
                        }
                        // console.log(`addWebOrderResult.status ${addWebOrderResult.status}`);
                        //localPath = http://localhost:5000/caterat-pos/asia-east2/api
                        //remotePath https://caterat-pos.df.r.appspot.com/listenIsRestaurantRecievedOrder?code=cateratdemo
                        const isRestaurantRecievedOrderRes = await axios.post(`https://caterat-pos.df.r.appspot.com/listenIsRestaurantRecievedOrder?code=${webOrderDetail.restaurantCode}`, {docId, listenerId}, { timeout: 10000 })
                                                                        .catch(isRecievedError=>{
                                                                            // throw {message: "交易已完成，但由於餐廳發生網絡錯誤，未能接取你的訂單，請聯絡餐廳進行退款"};
                                                                            // console.log(isRecievedError);
                                                                            alert("交易已完成");
                                                                            setLoading(false);
                                                                            cardElement.update({disabled: false});
                                                                            openOrderReview();
                                                                        });
                        // console.log(`addWebOrderResult.status ${addWebOrderResult.status}`);
                        // console.log(`isRestaurantRecievedOrderRes.status ${isRestaurantRecievedOrderRes.status}`);
                        if(addWebOrderResult.status === 200 && isRestaurantRecievedOrderRes.status === 200){
                            // setOrderDetails(addWebOrderResult.orderedDetails);
                            // setOrderNumber(addWebOrderResult.orderNumber);
                            alert("你的點餐通知已送達廚房");
                            setLoading(false);
                            cardElement.update({disabled: false});
                            openOrderReview();
                        }else{
                            alert("交易已完成");
                            setLoading(false);
                            cardElement.update({disabled: false});
                            openOrderReview();
                        }
                    }
                    setLoading(false);
                    cardElement.update({disabled: false});
                    // 20220414 Add refresh
                    window.location.reload(false);
                }
            }

        }catch(err){
            // if(err.includes("timeout")){
            //     setCardInputError("餐廳暫時不在線上，請稍後再作嘗試");
            // }else{
            // }
            // console.log(err);
            setCardInputError(err);
            setLoading(false);
            // const cardElement = elements.getElement(CardElement);
            // cardElement.update({disabled: false});
        }
        
    };
  
    return (
        <form onSubmit={handleSubmit}>
            <div className="modal" style={loading?{display: "block"}:{display: "none"}}>
                <Loading/>
            </div>
            <div className={`checkout-body ${isWebView&&"web-checkout-body"} h:full w:full bg:grey r:sm`}>
                <div className="head-bar"/>
                <div className="flex:1 c:row p:sm gap:sm">
                    <div class="c:col c:between align-items-center px:xs">
                        <h4>聯絡方式</h4>
                        {/* <div class="checkout-edit-btn btn bg:white border:all r:xs">
                            <i class="f:2xl f:light-grey material-icons-outlined">
                                    edit
                            </i>
                        </div> */}
                    </div>
                    <div class="checkout-info-box c:row bg:white r:sm shadow p:sm gap:sm">
                        <div>
                            <div className="c:col c:between"> 
                                <label for="tel">電話</label>
                                {errors.tel && <p class="error-msg">{errors.tel}</p>}
                            </div>
                            <input type="text" id="tel" name="tel" placeholder="例: 9123 1234" style={{width: "100%"}} onChange={telChangeHandler}/>
                        </div>
                        <div>
                            <div className="c:col c:between"> 
                                <label for="email">電郵</label>
                                {errors.email && <p class="error-msg">{errors.email}</p>}
                            </div>
                            <input type="text" id="email" name="email" placeholder="例: chantaiman@gmail.com" style={{width: "100%"}} onChange={emailChangeHandler}/>
                        </div>
                        <div>
                            <div className="c:col c:between"> 
                                <label for="name">名稱</label>
                                {errors.name && <p class="error-msg">{errors.name}</p>}
                            </div>
                            <input type="text" id="name" name="name" placeholder="例: 陳大文" style={{width: "100%"}} onChange={nameChangeHandler}/>
                        </div>
                    </div>
                
                    <div class="c:col c:between align-items-center px:xs">
                        <h4>付款方式</h4>
                        {/* <div class="checkout-edit-btn btn bg:white border:all r:xs">
                            <i class="f:2xl f:light-grey material-icons-outlined">
                                    edit
                            </i>
                        </div> */}
                    </div>
                    <div class="checkout-info-box h:auto c:row bg:white r:sm shadow p:sm gap:sm">
                        <label>信用卡</label>
                        <CardElement options={options} onChange={(e) => {
                            setCardInputError(e.error);
                            setCardComplete(e.complete);
                        }}/>
                        {cardInputError && <p class="error-msg">{cardInputError.message}</p>}
                    </div>
                    
                    <div id="web-terms-confirmation" className="c:col">
                        <input 
                        type="radio" 
                        name="terms-confirmation"
                        onClick={toggleAgreeTerms}
                        checked={agreeTerms}
                        ></input>
                        <p>本人已詳細閱讀及瞭解本網站<a href="/terms" target="_blank">使用條款</a>和<a href="/privacy" target="_blank">私隱條款</a>，亦同意受有關條款及細則所約束</p>
                    </div> 
                    <div className="sized-box-checkout"/>
                    <div className="checkout-btn-wrap" style={style}>
                        <button 
                        className="cart-checkout-btn"
                        style={{marginTop: "unset"}}
                        type="submit"
                        disabled={!stripe} 
                        // disabled
                        >
                            <h4>確認下單</h4>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
  };

export default Checkout;