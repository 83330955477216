import React, {useState, useEffect, useContext} from 'react';
import '../style/Root.css';
import './Cart.css';
import { widthContext } from '../../hooks/UseWindowWidth';
import { HeadBar } from '../Elements';
import { languageContext } from '../../hooks/UseLanguage';
import { postOrder } from '../../api/checkoutApi';
import {dummy} from '../newData';
import Loading from '../loading/Loading';


export default function Cart(props){
    const isWebView = useContext(widthContext);
    
    const {
        closeCart, 
        style
    } = props;


    return (
    (isWebView)?
    <div class="modal " style={style.web}>
        <div class="modal-content c:row h:full">
            <div class="modal-head py:sm">
                <div onClick={closeCart} class="head-btn close-btn bg:grey">
                        <i class="f:icon f:grey material-icons-outlined">
                            close
                        </i>
                </div>
            </div>
            <CartBody props={props} closeCart={closeCart}/>

        </div>
    </div>:
    <div class="app-cart" style={style.mobile}>
            <HeadBar className="cart-head" operation={closeCart} title="Shopping Cart"/>
            <div class="head-bar"></div>
            <CartBody style={style.mobile} props={props} closeCart={closeCart}/>
    </div>
    );
}

export const CartBody = React.memo(({style, props, closeCart})=>{
    const [total, setTotal] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const {
        checkoutCart,
        cartItems, 
        editCartItem, 
        removeCartItem, 
        openCheckout,
        setCartTotal
    } = props;

    useEffect(()=>{
        if(cartItems.length > 0){
            setTotal([...cartItems].reduce((total, {subtotal, quantity})=> total + subtotal*parseFloat(quantity), 0));
            setTotalItem([...cartItems].reduce((total, {quantity})=> total + quantity, 0));
        }
        if(cartItems.length === 0){
            setTotal(0);
            setTotalItem(0);
        }
    },[cartItems]);
    console.log(cartItems);
    const submitCart = async () => {
        const [order, error] = await postOrder(cartItems, total);

        if(error) {
            alert(error);
            return;
        }
    
        if(order) {
            alert(JSON.stringify(order, null, 2));
            closeCart();
        }
    }
    // if(isLoading){
    //     return(
    //         <div className="cart-overlay">
    //             <Loading style={{position: "relative"}}/>
    //         </div>
    //     );
    // }
    return(
    <div class="h:full">
        <div id="cart-body" class="cart-body h:full c:row p:sm gap:xs bg:grey r:sm">
            <div className="flex:1">
            {cartItems.map((item, index)=>{
                return(
                    <CartItem key={`cartItem-${index}`} editCartItem={editCartItem} removeCartItem={removeCartItem} item={item}/>
                );
            })}
            </div>
            <div class="cart-checkout c:row gap:sm" style={style}>
                <div className="p:sm">
                    <div class="review-total c:row w:full gap:sm align-items-end">
                        <h6>總共 ${total}</h6>
                        <h6>{totalItem} 項食物</h6>
                    </div>
                    <button
                    className="my:lg w:full"
                    onClick={()=>{
                                if(total===0){
                                    alert("Your cart is empty!")
                                }else{
                                    setIsLoading(true);
                                    setCartTotal(total);
                                    checkoutCart();
                                    setTimeout(()=>{
                                        closeCart();
                                        
                                    }, 1000);
                                    setIsLoading(false);
                                }
                            }} 
                    >確認落單</button>
                </div>
            </div>
        </div>
    </div>
    );
});


const findItemById = (myItemId, myStackId) => {
    const stackId = myStackId.split('@@@')[0];
    const itemId = myItemId.split('@@@')[0];
    return dummy.Menu.itemStacks.find(itemStack=>itemStack.id===stackId).content[itemId]?? undefined;
}


export const CartItem = ({editCartItem, removeCartItem, item})=>{
    const lang = useContext(languageContext);
    const myItem = {...item};
    const increment = ()=>{
        myItem.quantity = myItem.quantity+1; 
        editCartItem({cartId: myItem.cartId, value: myItem});
    }
    const decrement = ()=>{
        if(myItem.quantity > 1){
            myItem.quantity = myItem.quantity - 1; 
        }
        editCartItem({cartId: myItem.cartId, value: myItem});
    }

    // const img = myItem.mealInfo.img;
    const name = myItem.mealInfo[lang];
    const price = myItem.subtotal;
    // const price = myItem.mealInfo.dineInPrice;
    const img = myItem.mealInfo.img?? undefined;
    // const img = myItem.mealInfo.img;
    // const name = myItem.mealInfo[lang];
    // const price = myItem.mealInfo.takeawayPrice;

    let chosen = [];
    const subItems = myItem.selected.map(arr=>{
        const splited = arr[1][0].split("###");
        return findItemById(splited[1], splited[0]);
    }).filter(obj=>obj!==undefined);

    return (
    <div onClick={()=>{console.log(item)}} class="cart-item bg:white w:full c:col align-items-center p:sm r:sm shadow gap:sm">
        {img&&<div 
            class="cart-item-img r:sm bg px:sm" 
            style={{"background-image": `url(${img})`}}
            />}
        <div className="cart-item-block c:row justify-content-around">
            <h6 class="f:500">{name}</h6>
            <div class = "c:row">
                {subItems&&subItems.map((item, index)=>{
                    if(index < 3){
                        return <p key={`item-${index}`} class="f:400 f:grey">{item.priName}</p>
                    }
                    if(index === subItems.length-1){
                        return <p key={`item-${index}`} class="f:400 f:grey">...</p>
                    }
                })}

            </div>
        </div>
        <div className="flex:1"/>
        <div className="cart-item-block c:row align-items-center justify-content-around">
            <h6 class="c:col c:middle f:500">${price}</h6>
            <div class="cart-item-btn-gp c:col c:middle r:lg">
                    <div onClick={decrement} class="cart-item-btn center touch">
                        <i class="f:icon f:grey material-icons-outlined">
                            remove
                        </i>
                    </div>
                    <h6 class="f:orange">{myItem.quantity}</h6>
                    <div onClick={increment}  class="cart-item-btn center touch">
                        <i class="f:icon f:grey material-icons-outlined">
                            add
                        </i>
                    </div>
                </div>
        </div>
        <div onClick={()=>{removeCartItem(myItem.cartId)}} class="cart-item-block c:col c:middle gap:sm touch">
            <i class="f:icon f:grey material-icons-outlined">
            delete
            </i>
        </div>        
    </div>
    );
}

